import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import {
  ONDE_API_URL,
  OPED_DATA_DATASET,
  OPEN_DATA_URL,
} from "@helpers/constants"
import {
  BrevoPayloadType,
  SignUpPayloadType,
} from "@typings/containers/contact-us"

export const getPhoneNumberExample = (value: string) =>
  axios
    .get(ONDE_API_URL + "api/tel/example?value=" + value)
    .then((response: AxiosResponse) => response.data.example)
    .catch((error: AxiosError) => console.error("error", error))

export const checkIsPhoneValid = (value: string) =>
  axios
    .get(ONDE_API_URL + "api/tel/validate?value=" + value)
    .then((response: AxiosResponse) => response.data.example)
    .catch((error: AxiosError) => console.error("error", error))

export const getDefaultCountry = (options?: AxiosRequestConfig) =>
  axios
    .get("https://ip2c.org/s", options)
    .then((response: AxiosResponse) => {
      const result = response.data

      if (!result || result[0] !== "1") {
        throw new Error("unable to fetch the country")
      }
      return result.substr(2, 2)
    })
    .catch((error: AxiosError) => console.error(error))

export const sendBrevoEmail = (
  payload: Array<BrevoPayloadType | Pick<BrevoPayloadType, "email">>,
  list_id: number[],
  captchaToken?: string | undefined
) =>
  axios
    .post(ONDE_API_URL + "api/brevo", {
      listIds: list_id,
      payload: payload,
      token: captchaToken,
    })
    .then(() => ({ status: "success" }))
    .catch((error: AxiosError) => ({
      status: "error",
      error: error,
    }))

export const getCityData = (params: object) => {
  const query = new URLSearchParams({
    dataset: OPED_DATA_DATASET,
    ...params,
  })
  return axios
    .get(`${OPEN_DATA_URL}${query}`)
    .then((response: AxiosResponse) => response.data.records)
    .catch((error: AxiosError) => console.error(error))
}

export const signup = (payload: SignUpPayloadType) =>
  axios.put(process.env.API_SERVER_URL + "/invite/request", payload)

export const getBlogSearchResult = (
  query: string,
  locale: string,
  options: AxiosRequestConfig
) => {
  if (query) {
    return axios.get(
      process.env.STRAPI_API_URL +
        `/api/fuzzy-search/search?query=${query}&locale=${locale}`,
      options
    )
  }
}
