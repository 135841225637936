import React, { useEffect, useState } from "react"
import { graphql, navigate, PageProps } from "gatsby"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import Tabs from "@components/UI/Tabs"
import { getDefaultCountry } from "@services/api"
import { UserAgreementDataType } from "@typings/requests/user-agreement"

import "./terms-and-conditions.scss"

const UserAgreement: React.FC<PageProps<UserAgreementDataType>> = props => {
  const { strapiUserAgreement: userAgreementData }: UserAgreementDataType =
    props.data
  const { hash, pathname } = props.location
  const { agreements, header, title, metaImage, description } =
    userAgreementData

  const [activeTab, setActiveTab] = useState("")
  const toggleTags = () => {
    navigate(`#${agreements[0].title.toLocaleLowerCase()}`)
    return agreements[0].name
  }
  async function fetchCountryIso() {
    const countryIso: string = await getDefaultCountry({})
    const isTabsHasDefaultCountry = agreements.filter(
      item => item.name === countryIso
    )
    setActiveTab(isTabsHasDefaultCountry.length ? countryIso : toggleTags)
  }

  useEffect(() => {
    if (hash) {
      const tagName = hash.substring(1)
      const currentTab = agreements.filter(item =>
        item.title.toLocaleLowerCase().includes(tagName)
      )
      setActiveTab(currentTab[0]?.name)
    } else {
      fetchCountryIso()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SEO
        title={title}
        description={description}
        image={metaImage.localFile.publicURL}
        pathname={pathname}
        lang="en"
      />
      <LayoutContainer
        className="terms"
        location={props.location}
        colorLangSwitcher="black"
        hideLangSwitcher={true}
      >
        <section className="container terms__header">
          <h1 className="terms__title container__item text text--h1">
            {header}
          </h1>
        </section>
        <section className="container terms__tabs">
          <div className="container__item">
            <Tabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabs={agreements}
            />
          </div>
        </section>
      </LayoutContainer>
    </>
  )
}

export const query = graphql`
  {
    strapiUserAgreement {
      description
      title
      header
      metaImage {
        localFile {
          publicURL
        }
      }
      agreements {
        name
        text {
          data {
            text
          }
        }
        title
        id
      }
    }
  }
`

export default UserAgreement
